<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="https://cdn.jsdelivr.net/gh/dokku/dokku@v0.20.4/docs/assets/dokku.png" alt="Workflow">
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
        <errors v-if="errors.length > 0" v-bind:errors="errors" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      errors: [
        '404: Page not found'
      ]
    }
  }
}
</script>
