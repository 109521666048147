<template>
  <div class="bg-white shadow overflow-hidden sm:rounded-md">
    <ul role="list" class="divide-y divide-gray-200">
      <li>
        <a href="#" class="block hover:bg-gray-50">
          <div class="px-4 py-4 sm:px-6">
            <div class="flex items-center justify-between">
              <p class="text-sm font-medium text-blue-600 truncate">process type: {{ parseFormationName(formation.id) }}</p>
              <div class="ml-2 flex-shrink-0 flex">
                <p class="px-2 inline-flex text-xs leading-5 font-semibold rounded-full" :class="['bg-' + filterColor(formation.attributes.quantity) + '-100', 'text-' + filterColor(formation.attributes.quantity) + '-800']">current scale: {{ formation.attributes.quantity }}</p>
              </div>
            </div>
            <div class="mt-2 sm:flex sm:justify-between">
              <div class="flex flex-row h-16 w-full">
                <div class="flex flex-row w-1/2 mt-1">
                  <div>
                    <label class="block text-sm font-medium text-gray-700" :for="'quantity-' + formation.id">Process Count</label>
                    <div class="flex flex-row h-10 rounded-lg relative bg-transparent">
                      <button @click="decrement()" class="border-t border-b border-l text-sm leading-5 font-medium rounded-l text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 cursor-pointer w-10 h-full" :class="['border-' + filterColor() + '-300', 'active:bg-' + filterColor() + '-50']">
                        <span class="m-auto text-2xl font-thin">−</span>
                      </button>
                      <input type="number"  min="0" class="resource-scale outline-none focus:outline-none text-center w-12 border-t border-b font-semibold text-md hover:text-black focus:text-black md:text-basecursor-default flex items-center text-gray-700 outline-none" :class="['border-' + filterColor() + '-300']" :name="'quantity-' + formation.id" v-model="processCount">
                      <button @click="increment()" class="border-t border-b border-r text-sm leading-5 font-medium rounded-r text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:text-gray-800 cursor-pointer w-10 h-full" :class="['border-' + filterColor() + '-300', 'active:bg-' + filterColor() + '-50']" >
                        <span class="m-auto text-2xl font-thin">+</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div class="flex flex-row w-1/2 mt-1">
                  <div>
                    <label :for="'memory-' + formation.id" class="block text-sm font-medium text-gray-700">Memory</label>
                    <div>
                      <input type="number" min="0" autocomplete="off" class="shadow-sm focus:ring-blue-500 focus:border-blue-500 block w-full sm:text-sm border-gray-300 rounded-md" :name="'memory-' + formation.id" v-model="memory">
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-2 flex items-center text-sm text-gray-500 sm:mt-0">
                <button type="button" class="inline-flex items-center px-2.5 py-1.5 border border-transparent text-sm leading-4 font-medium rounded text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-700 focus:shadow-outline-blue active:bg-blue-700 transition ease-in-out duration-150" @click="saveFormation()"  :disabled="formation.injected.saving">
                  <icon-plus-circle class="-ml-1 mr-2 h-4 w-4 text-white" :class="{'animate-spin': formation.injected.saving}" />
                  Save
                </button>
              </div>
            </div>
          </div>
        </a>
      </li>
    </ul>
  </div>

</template>
<script>
import Api from '@/libraries/api.js'
import { PlusCircleIcon } from '@vue-hero-icons/outline'

export default {
  data () {
    return {
      errors: null,
      memory: null,
      processCount: null
    }
  },

  created () {
    this.errors = []
    this.processCount = this.formation.attributes.quantity
    this.memory = this.formation.attributes.reserves.memory || ''
  },

  props: {
    appName: {
      required: true,
      type: String
    },
    formation: {
      required: true,
      type: Object
    }
  },

  components: {
    'icon-plus-circle': PlusCircleIcon
  },

  methods: {
    decrement (index) {
      if (this.processCount > 0) {
        this.processCount -= 1
      }
    },

    increment (index) {
      this.processCount += 1
    },

    filterColor () {
      if (this.processCount === 0) {
        return 'yellow'
      }
      return 'green'
    },

    parseFormationName (name) {
      return name.split(':')[1]
    },

    saveFormation () {
      const that = this

      const data = {
        data: {
          type: 'formations',
          id: that.formation.id,
          attributes: {
            app_id: that.appName,
            process_type: that.formation.attributes.process_type,
            quantity: that.processCount
          }
        }
      }

      const data2 = {
        data: {
          type: 'resources',
          id: that.formation.id + ':reserve:memory',
          attributes: {
            app_id: that.appName,
            process_type: that.formation.attributes.process_type,
            resource_type: 'reserve',
            name: 'memory',
            value: that.memory.toString()
          }
        }
      }

      const onSuccess = () => {
        that.formation.quantity = that.processCount
        that.formation.attributes.reserves.memory = that.memory
        that.formation.injected.saving = false
      }

      const onError = (err) => {
        that.formation.injected.saving = false
        if (err.response) {
          err.response.data.errors.forEach(el => that.errors.push(el.title))
        } else if (err.request) {
          that.errors.push(err.request)
        } else {
          that.errors.push(err.toString())
        }
      }

      const modifiesMemory = that.memory.toString() !== that.formation.attributes.reserves.memory
      const modifiesProcessCount = that.processCount !== that.formation.attributes.quantity

      console.log(that.formation.attributes)
      console.log(that.memory, that.memory.toString(), that.formation.attributes.reserves.memory)
      console.log(that.processCount, that.formation.attributes.quantity)

      that.formation.injected.saving = true
      if (modifiesMemory && modifiesProcessCount) {
        Api.componentScaleUpdate(data, data2, onSuccess, onError)
      } else if (modifiesMemory) {
        Api.updateResource(data2, onSuccess, onError)
      } else if (modifiesProcessCount) {
        Api.updateFormation(data, onSuccess, onError)
      } else {
        onSuccess()
      }
    }
  }
}
</script>
