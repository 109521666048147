<template>
  <div>
    <loading v-if="loading" />

    <errors v-bind:errors="errors" />

    <div v-if="app" >
      <app-header :name="app.id"></app-header>

      <div class="px-4 py-5 sm:px-6">
       <div class="pb-5">
         <p class="text-sm leading-5 text-gray-500">
           Application log output is streamed below. If there are no running processes, the output here may be blank.
         </p>
       </div>

       <div class="pb-5">
          <pre class="block scrollbar-none m-0 p-0 overflow-auto text-white text-sm bg-gray-800 leading-normal rounded-lg"><div v-if="loadingLogs" class="flex flex-column items-center justify-center h4 bg-black-40 my-10">
              <spinner size="huge" message="loading logs" />
            </div><code v-else class="inline-block p-4 scrolling-touch subpixel-antialiased language-html"><span v-for="line in logs" :key="line.id">{{line.message}}
</span>
          </code></pre>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
import Api from '@/libraries/api.js'
import ApiUrlService from '@/libraries/api-url-service.js'
import TokenStorage from '@/libraries/token-storage.js'

export default {
  data () {
    return {
      app: null,
      appName: null,
      errors: null,
      loading: false,
      logs: null,
      loadingLogs: false
    }
  },

  created () {
    this.appName = this.$route.params.name
    this.logs = []
    this.loadingLogs = true
    this.fetchData()
    this.fetchTempToken()
    this.$on('fetchLogs', (data) => this.fetchLogs(data))
  },

  beforeRouteEnter (to, from, next) {
    return TokenStorage.isAuthenticated() ? next() : next('/')
  },

  components: {
  },

  methods: {
    fetchData () {
      this.errors = []
      this.app = null
      this.loading = true
      Api.getApp(this.appName, '', (response) => {
        response.data.data.injected = {
          deleting: false,
          saving: false
        }

        this.app = response.data.data
        this.loading = false
        this.$emit('fetched')
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    fetchTempToken () {
      const that = this
      TokenStorage.getNewTemporaryToken()
        .then((response) => {
          that.$emit('fetchLogs', response)
        })
        .catch((err) => {
          if (err.response) {
            err.response.data.errors.forEach(el => this.errors.push(el.title))
          } else if (err.request) {
            this.errors.push(err.request)
          } else {
            this.errors.push(err.toString())
          }
        })
    },

    fetchLogs (token) {
      const that = this
      const appName = this.$route.params.name

      this.connection = new WebSocket(ApiUrlService.appLogs(appName, token))

      this.connection.onmessage = function (event) {
        const msg = JSON.parse(event.data)
        that.logs.push(msg)
        that.loadingLogs = false
      }

      this.connection.onopen = function (event) {
        console.log('Successfully connected to the websocket server...')
      }

      this.connection.onerror = function (event) {
        that.loadingLogs = false
      }

      this.connection.onclose = function (event) {
        that.loadingLogs = false
      }
    }
  },

  watch: {
    $route: 'fetchData'
  }
}
</script>
