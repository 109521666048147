<template>
  <div v-if="errors.length > 0" class="rounded-md bg-red-50 p-4">
    <div class="flex">
      <div class="flex-shrink-0">
        <icon-x-circle class="h-5 w-5 text-red-400"></icon-x-circle>
      </div>
      <div class="ml-3">
        <h3 class="text-sm font-medium text-red-800">
          There were {{ errors.length }} errors on the request:
        </h3>
        <div class="mt-2 text-sm text-red-700">
          <ul class="list-disc pl-5 space-y-1">
            <li v-for="error in errors" :key="error">{{error}}</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { XCircleIcon } from '@vue-hero-icons/solid'

export default {
  props: ['errors'],
  components: {
    'icon-x-circle': XCircleIcon
  }
}
</script>
