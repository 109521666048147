<template>
  <div>
    <loading v-if="loading" />

    <errors v-bind:errors="errors" />

    <div v-if="service" >
      <service-header :name="serviceName" :service_type="serviceType"></service-header>

      <div class="px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 col-gap-4 row-gap-8 sm:grid-cols-2">
          <div class="sm:col-span-1">
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Status
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5" :class="service.attributes.status | service_status_class" :data-status="service.attributes.status">
              <span v-html="this.$options.filters.service_status_icon(service.attributes.status)"></span> {{ service.attributes.status | titleize }} <span v-if="'container_id' in service.attributes">({{ service.attributes.container_id | truncate(12) }})</span>
            </dd>

            <dt class="text-sm leading-5 font-medium text-gray-500">
              Docker Image
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-image="service.attributes.image">
              {{ service.attributes.image }}
            </dd>

            <dt class="text-sm leading-5 font-medium text-gray-500">
              Internal IP
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-internal-ip="service.attributes.internal_ip">
              {{ service.attributes.internal_ip }}
            </dd>

            <dt class="text-sm leading-5 font-medium text-gray-500">
              Exposed Ports
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-exposed-ports="service.attributes.exposed_ports">
              <ul v-if="'exposed_ports' in service.attributes" ><li v-for="port in service.attributes.exposed_ports" :key="port">{{port}}</li></ul>
              <span v-else>Service not currently exposed</span>
            </dd>
          </div>

          <div class="sm:col-span-1">
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Config Directory
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-config-dir="service.attributes.config_dir">
              {{ service.attributes.config_dir }}
            </dd>
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Service Directory
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-data-dir="service.attributes.data_dir">
              {{ service.attributes.data_dir }}
            </dd>
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Service Directory
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-service-dir="service.attributes.service_dir">
              {{ service.attributes.service_dir }}
            </dd>

            <dt class="text-sm leading-5 font-medium text-gray-500">
              Linked Apps
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-exposed-ports="service.attributes.links">
              <ul v-if="'links' in service.attributes" ><li v-for="app in service.attributes.links" :key="app"><router-link :to="'/apps/' + app">{{ app }}</router-link></li></ul>
              <span v-else>Service not currently linked to any apps</span>
            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/libraries/api.js'
import TokenStorage from '@/libraries/token-storage.js'

export default {
  data () {
    return {
      service: null,
      serviceName: null,
      serviceType: null,
      errors: [],
      loading: false
    }
  },

  created () {
    this.serviceName = this.parseServiceName(this.$route.params.name)
    this.serviceType = this.parseServiceType(this.$route.params.name)
    this.fetchData()
  },

  beforeRouteEnter (to, from, next) {
    return TokenStorage.isAuthenticated() ? next() : next('/')
  },

  filters: {
    titleize (value) {
      return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
    },

    service_status_class (s) {
      if (s === 'running') {
        return 'text-green-700'
      }

      if (s === 'mixed') {
        return 'text-yellow-600'
      }

      if (s === 'stopped') {
        return 'text-red-700'
      }

      return 'text-gray-600'
    },

    service_status_icon (s) {
      if (s === 'running') {
        return '<svg style="display:inline" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="inline-block mr-2 h-4 w-4"><path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
      }

      if (s === 'mixed') {
        return '<svg style="display:inline" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="inline-block mr-2 h-4 w-4"><path d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
      }

      if (s === 'stopped') {
        return '<svg style="display:inline" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 16 16" class="inline-block mr-2 h-4 w-4"><path d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
      }

      return '<svg style="display:inline" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 32 32" class="w-8 h-8"><path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
    },

    truncate (s, length) {
      return s.substring(0, length)
    }
  },

  methods: {
    fetchData () {
      this.errors = []
      this.service = null
      this.loading = true
      Api.getService(this.serviceType, this.serviceName, (response) => {
        this.service = response.data.data
        this.loading = false
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    parseServiceName (name) {
      return name.split(':')[1]
    },

    parseServiceType (name) {
      return name.split(':')[0]
    }
  },

  watch: {
    $route: 'fetchData'
  }
}
</script>
