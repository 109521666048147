export default {
  appBuilderSetting (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name + '/builderSetting'
  },

  appsCreate () {
    return this.scheme() + '://' + this.hostname() + '/@api/apps'
  },

  appsDelete (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name
  },

  appsList () {
    return this.scheme() + '://' + this.hostname() + '/@api/apps'
  },

  appLogs (appName, token) {
    if (this.scheme() === 'http') {
      return 'ws://' + this.hostname() + '/@api/apps/' + appName + '/logs?access_token=' + token
    }

    return 'wss://' + this.hostname() + '/@api/apps/' + appName + '/logs?access_token=' + token
  },

  appsView (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name
  },

  appsUpdate (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name
  },

  appDomains (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name + '/domains'
  },

  appEnv (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name + '/envs'
  },

  appFormation (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name + '/formations'
  },

  appResource (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name + '/resources'
  },

  appSchedulerSetting (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name + '/schedulerSetting'
  },

  appServiceRelationships (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/apps/' + name + '/relationships/services'
  },

  buildersList () {
    return this.scheme() + '://' + this.hostname() + '/@api/builders'
  },

  builderSettingUpdate (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/builderSettings/' + name
  },

  datastoresList () {
    return this.scheme() + '://' + this.hostname() + '/@api/datastores'
  },

  domainCreate () {
    return this.scheme() + '://' + this.hostname() + '/@api/domains'
  },

  domainDelete (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/domains/' + name
  },

  envCreate () {
    return this.scheme() + '://' + this.hostname() + '/@api/envs'
  },

  envDelete (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/envs/' + name
  },

  formationCreate () {
    return this.scheme() + '://' + this.hostname() + '/@api/formations'
  },

  formationDelete (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/formations/' + name
  },

  hostname () {
    if ('VUE_APP_DOKKU_API_HOSTNAME' in process.env) {
      return process.env.VUE_APP_DOKKU_API_HOSTNAME
    }

    if (window.location.host === 'localhost:3000') {
      return 'localhost:8080'
    }

    return window.location.host
  },

  login () {
    return this.scheme() + '://' + this.hostname() + '/@api/tokens'
  },

  refreshToken () {
    return this.scheme() + '://' + this.hostname() + '/@api/tokens'
  },

  resourceCreate () {
    return this.scheme() + '://' + this.hostname() + '/@api/resources'
  },

  schedulersList () {
    return this.scheme() + '://' + this.hostname() + '/@api/schedulers'
  },

  schedulerSettingUpdate (name) {
    return this.scheme() + '://' + this.hostname() + '/@api/schedulerSettings/' + name
  },

  scheme () {
    if ('VUE_APP_DOKKU_API_SCHEME' in process.env) {
      return process.env.VUE_APP_DOKKU_API_SCHEME
    }

    if (window.location.protocol === 'http:') {
      return 'http'
    }

    return 'https'
  },

  serviceCreate () {
    return this.scheme() + '://' + this.hostname() + '/@api/services'
  },

  serviceDelete (serviceType, name) {
    return this.scheme() + '://' + this.hostname() + '/@api/services/' + serviceType + ':' + name
  },

  serviceList () {
    return this.scheme() + '://' + this.hostname() + '/@api/services'
  },

  serviceLogs (serviceType, name, token) {
    if (this.scheme() === 'http') {
      return 'ws://' + this.hostname() + '/@api/services/' + serviceType + ':' + name + '/logs?access_token=' + token
    }

    return 'wss://' + this.hostname() + '/@api/services/' + serviceType + ':' + name + '/logs?access_token=' + token
  },

  serviceView (serviceType, name) {
    return this.scheme() + '://' + this.hostname() + '/@api/services/' + serviceType + ':' + name
  }
}
