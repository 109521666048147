<template>
  <ul class="breadcrumbs" v-if="crumbs.length">
    <li v-for="(crumb) in crumbs" :key="crumb.name">
      <router-link :to="crumb.path">{{ crumb.name }}</router-link><span v-if="crumb.separator">&nbsp;/&nbsp;</span>
    </li>
  </ul>
</template>

<style>
.breadcrumbs {
  list-style: none;
  margin:0;
  padding:0;
  display:inline-block;
}
.breadcrumbs li {
  display: inline-block;
  @apply pl-1
}
</style>

<script>
export default {
  props: { root: String },

  computed: {
    crumbs: function () {
      let path = ''
      let title = (this.root || '')

      if (!this.$route) {
        return []
      }

      const r = (this.$route.path).split('/')
      const m = ''.split('/')

      const cs = []

      for (let i = 1; i < r.length; i++) {
        const name = (m[i] || r[i])
        let separator = true
        if (r[i] === '') {
          continue
        }

        if (i === 1) {
          title += name
        } else {
          title += ' / ' + name
        }
        path += '/' + name

        if (i === 1 && r.length === 1) {
          separator = false
        }

        if (i === r.length - 1) {
          separator = false
        }

        cs.push({
          name: name,
          path: path,
          separator: separator
        })
      }

      window.document.title = title

      return cs
    }
  }
}
</script>
