import axios from 'axios'
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'
import { differenceInMilliseconds, fromUnixTime, isPast, subMinutes } from 'date-fns'
import { Promise } from 'es6-promise'

import ApiUrlService from '@/libraries/api-url-service.js'
import router from '@/router'

const LOCAL_STORAGE_TOKEN = 'jwt:token'
const LOCAL_STORAGE_TOKEN_EXPIRATION = 'jwt:token_expiration'
const LOCAL_STORAGE_REFRESH_TOKEN = 'jwt:refresh_token'

export default {
  isAuthenticated () {
    return this.getToken() !== null
  },

  getAuthentication () {
    return {
      headers: {
        Authorization: 'Bearer ' + this.getToken()
      }
    }
  },

  getNewTemporaryToken () {
    return new Promise((resolve, reject) => {
      const headers = {
        Authorization: `Bearer ${this.getToken()}`,
        'Content-Type': 'application/vnd.api+json'
      }
      const data = {
        data: {
          type: 'tokens',
          attributes: {
            uses: 1
          }
        }
      }
      axios
        .post(ApiUrlService.refreshToken(), data, { headers: headers })
        .then(response => {
          resolve(response.data.data.id)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  getNewToken () {
    if (!this.getRefreshToken()) {
      return new Promise((resolve, reject) => {
        reject(new Error('No refresh token exists'))
      })
    }

    return new Promise((resolve, reject) => {
      const headers = {
        Authorization: `Bearer ${this.getRefreshToken()}`,
        'Content-Type': 'application/vnd.api+json'
      }
      const data = {
        data: {
          type: 'tokens'
        }
      }
      axios
        .post(ApiUrlService.refreshToken(), data, { headers: headers })
        .then(response => {
          const decoded = jwt_decode(response.data.data.id)
          this.storeTokenExpiration(decoded.exp)
          this.storeToken(response.data.data.id)
          this.storeRefreshToken(response.data.data.attributes.refresh_token)

          resolve(response.data.data.id)
        })
        .catch((error) => {
          reject(error)
        })
    })
  },

  isTokenExpired () {
    const tokenExpiryDate = fromUnixTime(this.getTokenExpiration())
    return isPast(tokenExpiryDate)
  },

  setupTokenRefresh () {
    const tokenExpiryDate = fromUnixTime(this.getTokenExpiration())
    const tenMinutesBeforeExpiry = subMinutes(tokenExpiryDate, 10)
    const now = new Date()

    if (isPast(tokenExpiryDate)) {
      return
    }

    var that = this
    setTimeout(function () {
      that.refreshTokens()
    }, differenceInMilliseconds(tenMinutesBeforeExpiry, now))
  },

  refreshTokens () {
    var that = this
    this.getNewToken()
      .then(() => {
        that.setupTokenRefresh()
      })
      .catch(() => {
        that.clear()
        if (router.currentRoute.name !== 'login') {
          return router.push({
            name: 'login'
          })
        }
      })
  },

  storeRefreshToken (refreshToken) {
    localStorage.setItem(LOCAL_STORAGE_REFRESH_TOKEN, refreshToken)
  },

  storeToken (token) {
    localStorage.setItem(LOCAL_STORAGE_TOKEN, token)
  },

  storeTokenExpiration (expiration) {
    localStorage.setItem(LOCAL_STORAGE_TOKEN_EXPIRATION, expiration)
  },

  clear () {
    localStorage.removeItem(LOCAL_STORAGE_REFRESH_TOKEN)
    localStorage.removeItem(LOCAL_STORAGE_TOKEN)
    localStorage.removeItem(LOCAL_STORAGE_TOKEN_EXPIRATION)
  },

  getRefreshToken () {
    return localStorage.getItem(LOCAL_STORAGE_REFRESH_TOKEN)
  },

  getToken () {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN)
  },

  getTokenExpiration () {
    return localStorage.getItem(LOCAL_STORAGE_TOKEN_EXPIRATION)
  }
}
