<template>
  <div>
    <loading v-if="loading" />

    <errors v-else-if="errors.length > 0" v-bind:errors="errors" />

    <div v-else-if="datastores">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
          <div class="ml-4 mt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              available services
            </h3>
          </div>

          <div class="ml-4 mt-4 flex-shrink-0 flex">
            <span class="inline-flex rounded-md shadow-sm">
              <button @click="addServiceModalIsOpen = !addServiceModalIsOpen" class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue  active:bg-blue-700 transition ease-in-out duration-150">
                <icon-plus-circle class="-ml-1 mr-2 h-5 w-5 text-white-400"/>
                Add Service
              </button>
            </span>

            <modal :showing="addServiceModalIsOpen" @close="addServiceModalIsOpen = false">
              <div>
                <div class="mt-3 text-left sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900" id="dialog-1-title">
                    Add a service
                  </h3>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <loading v-if="addServiceLoading" />
                <div v-else >
                  <errors v-bind:errors="addServiceErrors" />

                  <form class="space-y-6" action="#" method="POST">
                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label for="country" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Type
                      </label>
                      <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <select id="type" name="type" v-model="serviceType" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 rounded-md">
                          <option v-for="(datastore) in datastores"  :key="datastore.id" :value="datastore.id">{{ datastore.id }}</option>
                        </select>
                      </div>
                    </div>

                    <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 sm:pt-5">
                      <label for="last-name" class="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Name
                      </label>
                      <div class="mt-1 sm:mt-0 sm:col-span-2">
                        <input id="name" type="text" v-model="serviceName" required class="max-w-lg block w-full shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:max-w-xs sm:text-sm border-gray-300 rounded-md" />
                      </div>
                    </div>

                    <div>
                      <button @click="addService(serviceType, serviceName)" type="submit" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm" :disabled="addServiceLoading">
                        <icon-check-circle class="-ml-1 mr-2 h-5 w-5 text-white-400" :class="{'animate-spin': addServiceLoading}" />
                        Create Service
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </modal>
          </div>

        </div>
      </div>

      <table class="min-w-full">
        <thead>
          <tr>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(service, index) in services" :key="service.id" :class="{'bg-gray-50': index % 2, 'bg-white': !(index % 2)}">
            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
              <router-link :to="'/services/' + service.id" class="text-gray-900">{{ service.id }}</router-link>
              <div class="text-xs text-gray-500">{{ service.attributes.service_type }}</div>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
              <router-link :to="'/services/' + service.id" class="text-indigo-600 hover:text-indigo-900">View</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else>
      Empty state
    </div>
  </div>
</template>

<script>
import Api from '@/libraries/api.js'
import TokenStorage from '@/libraries/token-storage.js'
import { CheckCircleIcon, PlusCircleIcon } from '@vue-hero-icons/outline'

export default {
  data () {
    return {
      datastores: null,
      services: null,
      errors: [],
      appName: null,
      loading: false,
      addServiceErrors: [],
      addServiceModalIsOpen: false,
      addServiceLoading: false
    }
  },

  beforeRouteEnter (to, from, next) {
    return !TokenStorage.isAuthenticated() ? next('/') : next()
  },

  created () {
    this.serviceName = ''
    this.serviceType = ''
    this.fetchData()
  },

  components: {
    'icon-check-circle': CheckCircleIcon,
    'icon-plus-circle': PlusCircleIcon
  },

  methods: {
    addService (serviceType, serviceName) {
      if (serviceName.length === 0) {
        return
      }

      this.addServiceErrors = []
      this.addServiceLoading = true
      const data = {
        data: {
          type: 'services',
          id: serviceType + ':' + serviceName,
          attributes: {
            locked: false
          }
        }
      }

      Api.createService(data, (response) => {
        this.addServiceLoading = false
        this.addServiceModalIsOpen = false
        this.$router.push({
          name: 'serviceView',
          params: {
            type: serviceType,
            name: response.data.data.id
          }
        })
      }, (err) => {
        this.addServiceLoading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.addServiceErrors.push(el.title))
        } else if (err.request) {
          this.addServiceErrors.push(err.request)
        } else {
          this.addServiceErrors.push(err.toString())
        }
      })
    },

    fetchData () {
      this.errors = []
      this.datastores = []
      this.services = []
      this.loading = true
      Api.componentDatastoresList((datastoresResponse, servicesResponse) => {
        this.datastores = datastoresResponse.data.data
        this.services = servicesResponse.data.data
        this.loading = false
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    }
  },

  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  }
}
</script>
