<template>
  <div>
    <loading v-if="loading" />

    <errors v-else-if="errors.length > 0" v-bind:errors="errors" />

    <div v-else-if="apps">
      <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
        <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
          <div class="ml-4 mt-4">
            <h3 class="text-lg leading-6 font-medium text-gray-900">
              app index
            </h3>
          </div>
          <!-- <div class="ml-4 mt-4 flex-shrink-0"  > -->
            <!-- <span class="sm:block ml-3 shadow-sm rounded-md"> -->
          <div class="ml-4 mt-4 flex-shrink-0 flex">
            <span class="inline-flex rounded-md shadow-sm">
              <button @click="addAppModalIsOpen = !addAppModalIsOpen" class="relative inline-flex items-center px-4 py-2 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-blue-600 hover:bg-blue-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue  active:bg-blue-700 transition ease-in-out duration-150">
                <icon-plus-circle class="-ml-1 mr-2 h-5 w-5 text-white-400"/>
                Add App
              </button>
            </span>

            <modal :showing="addAppModalIsOpen" @close="addAppModalIsOpen = false">
              <div>
                <div class="mt-3 text-left sm:mt-5">
                  <h3 class="text-lg leading-6 font-medium text-gray-900" id="dialog-1-title">
                    Add an app
                  </h3>
                </div>
              </div>
              <div class="mt-5 sm:mt-6">
                <loading v-if="addAppLoading" />
                <div v-else >
                  <errors v-bind:errors="addAppErrors" />

                  <form class="space-y-6" action="#" method="POST">
                    <div>
                      <label for="name" class="block text-sm font-medium text-gray-700">
                        App Name
                      </label>
                      <div class="mt-1">
                        <input id="name" type="text" v-model="appName" required autofocus class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
                      </div>
                    </div>

                    <div>
                      <button @click="addApp(appName)" type="submit" class="inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm" :disabled="addAppLoading">
                        <icon-check-circle class="-ml-1 mr-2 h-5 w-5 text-white-400" :class="{'animate-spin': addAppLoading}" />
                        Create App
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </modal>
          </div>
        </div>
      </div>

      <table class="min-w-full">
        <thead>
          <tr>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs leading-4 font-medium text-gray-500 uppercase tracking-wider">
              Name
            </th>
            <th class="px-6 py-3 border-b border-gray-200 bg-gray-50"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(app, index) in apps" :key="app.id" :class="{'bg-gray-50': index % 2, 'bg-white': !(index % 2)}">
            <td class="px-6 py-4 whitespace-no-wrap text-sm leading-5 font-medium text-gray-900">
              <router-link :to="'/apps/' + app.id" class="text-gray-900">{{ app.id }}</router-link>
            </td>
            <td class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 font-medium">
              <router-link :to="'/apps/' + app.id" class="text-indigo-600 hover:text-indigo-900">View</router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-else>
      Empty state
    </div>
  </div>
</template>

<script>
import Api from '@/libraries/api.js'
import TokenStorage from '@/libraries/token-storage.js'
import { CheckCircleIcon, PlusCircleIcon } from '@vue-hero-icons/outline'

export default {
  data () {
    return {
      apps: null,
      errors: [],
      appName: null,
      loading: false,
      addAppErrors: [],
      addAppModalIsOpen: false,
      addAppLoading: false
    }
  },

  beforeRouteEnter (to, from, next) {
    return !TokenStorage.isAuthenticated() ? next('/') : next()
  },

  created () {
    this.appName = ''
    this.fetchData()
  },

  components: {
    'icon-check-circle': CheckCircleIcon,
    'icon-plus-circle': PlusCircleIcon
  },

  methods: {
    addApp (appName) {
      if (this.appName.length === 0) {
        return
      }

      this.addAppErrors = []
      this.addAppLoading = true
      const data = {
        data: {
          type: 'apps',
          id: appName,
          attributes: {
            locked: false
          }
        }
      }

      Api.createApp(data, (response) => {
        this.addAppLoading = false
        this.addAppModalIsOpen = false
        this.$router.push({
          name: 'appView',
          params: {
            name: response.data.data.id
          }
        })
      }, (err) => {
        this.addAppLoading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.addAppErrors.push(el.title))
        } else if (err.request) {
          this.addAppErrors.push(err.request)
        } else {
          this.addAppErrors.push(err.toString())
        }
      })
    },

    fetchData () {
      this.errors = []
      this.apps = null
      this.loading = true
      Api.getApps((response) => {
        this.apps = response.data.data
        this.loading = false
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    }
  },

  watch: {
    // call again the method if the route changes
    $route: 'fetchData'
  }
}
</script>
