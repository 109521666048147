<template>
  <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
    <div class="-ml-4 -mt-4 flex justify-between items-center flex-wrap sm:flex-no-wrap">
      <div class="ml-4 mt-4">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          {{ service_type }}: {{ name }}
        </h3>
        <p class="mt-1 text-sm leading-5 text-gray-500">
        </p>
      </div>
      <!-- <div class="ml-4 mt-4 flex-shrink-0"  > -->
        <!-- <span class="sm:block ml-3 shadow-sm rounded-md"> -->
      <div class="ml-4 mt-4 flex-shrink-0 flex">
        <span class="inline-flex rounded-md shadow-sm">
          <router-link :to="'/services/' + service_type + ':' + name"  class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-50 active:text-gray-800">
            <icon-template class="-ml-1 mr-2 h-5 w-5 text-gray-400" />
            Overview
          </router-link>
        </span>

        <span class="sm:block ml-3 shadow-sm rounded-md">
          <router-link :to="'/services/' + service_type + ':' + name + '/logs'" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-50 active:text-gray-800">
            <icon-terminal class="-ml-1 mr-2 h-5 w-5 text-gray-400" />
            Logs
          </router-link>
        </span>

        <span class="ml-3 inline-flex rounded-md shadow-sm">
          <router-link :to="'/services/' + service_type + ':' + name + '/settings'" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm leading-5 font-medium rounded-md text-gray-700 bg-white hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-gray-50 active:text-gray-800">
            <icon-cog class="-ml-1 mr-2 h-5 w-5 text-gray-400" />
            Settings
          </router-link>
        </span>

      </div>
    </div>
  </div>
</template>

<script>
import { CogIcon, TemplateIcon, TerminalIcon } from '@vue-hero-icons/outline'

export default {
  components: {
    'icon-cog': CogIcon,
    'icon-template': TemplateIcon,
    'icon-terminal': TerminalIcon
  },

  props: ['name', 'service_type']
}
</script>
