<template>
  <div>
    <loading v-if="loading" />

    <errors v-bind:errors="errors" />

    <div v-if="envs" >
      <app-header :name="appName"></app-header>

      <div class="px-4 py-5 sm:px-6">
        <div class="pb-5">
          <p class="text-sm leading-5 text-gray-500">
            Environment variables let you add sensitive data (e.g. API keys) to your apps rather than placing them in the repository.
          </p>
        </div>

        <form>
          <div v-for="(env, index) in envs" :key="env.id" class="mb-2 flex">
            <div class="flex-none w-56">
              <label :for="'env[' + env.id + ']'" class="sr-only">
                Environment Variable Name
              </label>
              <div class="mt-1">
                <input type="text" :id="'env[' + env.id + ']'" class="w-11/12 rounded" v-model="env.attributes.key" :readonly="!env.injected.temporary"/>
              </div>
            </div>

            <div class="flex-grow">
              <label :for="'env[' + env.id + ']'" class="sr-only">
                Environment Variable Value
              </label>
              <div class="mt-1">
                <input type="text" :id="'env[' + env.id + ']'" class="w-full rounded" v-model="env.attributes.value" />
              </div>
            </div>
            <div class="flex-none">
              <div class="ml-3 pt-1 inline-flex rounded-md">
                <button class="relative inline-flex items-center px-2 py-2 mr-2 border border-green-300 text-xs leading-5 font-medium rounded-md text-green-700 bg-white hover:text-green-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-green-50 active:text-green-800" @click.prevent="saveEnv(index, envs)"  :disabled="env.injected.saving">
                  <icon-check-circle class="mr-2 h-5 w-5 text-green-400" :class="{'animate-spin': env.injected.saving}" />
                  {{ env.injected.temporary ? 'Add' : 'Update' }}
                </button>
                <button class="relative inline-flex items-center px-2 py-2 border border-red-300 text-xs leading-5 font-medium rounded-md text-red-700 bg-white hover:text-red-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-red-50 active:text-red-800" @click.prevent="removeEnv(index, envs)" :disabled="env.injected.removing">
                  <icon-x-circle class="h-5 w-5 text-red-400" :class="{'animate-spin': env.injected.removing}" /></button>
              </div>
            </div>
          </div>
          <button class="relative inline-flex items-center px-4 py-2 border border-blue-300 text-xs leading-5 font-medium rounded-md text-blue-700 bg-white hover:text-blue-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 active:bg-blue-50 active:text-blue-800" @click.prevent="addEnv(envs)">
            <icon-plus-circle class="-ml-1 mr-2 h-5 w-5 text-blue-400" />
            Add New Environment Variable
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/libraries/api.js'
import TokenStorage from '@/libraries/token-storage.js'
import { CheckCircleIcon, PlusCircleIcon, XCircleIcon } from '@vue-hero-icons/outline'

export default {
  data () {
    return {
      appName: null,
      envs: null,
      errors: null,
      loading: false
    }
  },

  created () {
    this.appName = this.$route.params.name
    this.fetchData()
  },

  beforeRouteEnter (to, from, next) {
    return TokenStorage.isAuthenticated() ? next() : next('/')
  },

  components: {
    'icon-check-circle': CheckCircleIcon,
    'icon-plus-circle': PlusCircleIcon,
    'icon-x-circle': XCircleIcon
  },

  methods: {
    fetchData () {
      this.errors = []
      this.envs = null
      this.loading = true
      Api.getEnv(this.appName, (envResponse) => {
        for (const element of envResponse.data.data) {
          element.injected = {
            saving: false,
            removing: false,
            temporary: false
          }
        }

        this.envs = envResponse.data.data
        this.loading = false
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    addEnv (envs) {
      envs.push({
        type: 'envs',
        id: 'test:' + this.$uuid.v4(),
        injected: {
          saving: false,
          removing: false,
          temporary: true
        },
        attributes: {
          app_id: this.appName,
          key: '',
          value: ''
        }
      })
    },

    removeEnv (index, envs) {
      if (envs[index].injected.temporary) {
        envs.splice(index, 1)
        return
      }

      envs[index].injected.removing = true
      Api.deleteEnv(envs[index].id, () => {
        envs[index].injected.removing = false
        envs.splice(index, 1)
      }, (err) => {
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    saveEnv (index, envs) {
      envs[index].injected.saving = true

      const id = this.appName + ':' + envs[index].attributes.key
      const data = {
        data: {
          type: 'envs',
          id: id,
          attributes: {
            app_id: this.appName,
            key: envs[index].attributes.key,
            value: envs[index].attributes.value
          }
        }
      }

      Api.createEnv(data, () => {
        envs[index].id = id
        envs[index].injected.temporary = false
        envs[index].injected.saving = false
      }, (err) => {
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    }
  },

  watch: {
    $route: 'fetchData'
  }
}
</script>
