<template>
  <div class="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
    <div class="sm:mx-auto sm:w-full sm:max-w-md">
      <img class="mx-auto h-12 w-auto" src="https://cdn.jsdelivr.net/gh/dokku/dokku@v0.20.4/docs/assets/dokku.png" alt="Workflow">
    </div>

    <div class="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
      <div class="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">

        <loading v-if="loading" />
        <div v-else >
          <errors v-bind:errors="errors" />

          <form class="space-y-6" action="#" method="POST">
            <div>
              <label for="username" class="block text-sm font-medium text-gray-700">
                Username
              </label>
              <div class="mt-1">
                <input id="username" type="text" v-model="username" required autofocus class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              </div>
            </div>

            <div>
              <label for="password" class="block text-sm font-medium text-gray-700">
                Password
              </label>
              <div class="mt-1">
                <input id="password" type="password" v-model="password" required class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm">
              </div>
            </div>

            <div>
              <button type="submit" @click="handleSubmit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line camelcase
import jwt_decode from 'jwt-decode'

import ApiUrlService from '@/libraries/api-url-service.js'
import TokenStorage from '@/libraries/token-storage.js'

export default {
  data () {
    return {
      username: '',
      password: '',
      errors: null,
      loading: false
    }
  },

  created () {
    this.errors = []
  },

  methods: {
    handleSubmit (e) {
      e.preventDefault()

      if (this.password.length === 0) {
        return
      }

      this.errors = []
      this.loading = true
      const token = Buffer.from(`${this.username}:${this.password}`, 'utf8').toString('base64')

      const headers = {
        Authorization: `Basic ${token}`,
        'Content-Type': 'application/vnd.api+json'
      }
      const data = {
        data: {
          type: 'tokens'
        }
      }

      this.$http
        .post(ApiUrlService.login(), data, { headers: headers })
        .then((response) => {
          this.loading = false

          const decoded = jwt_decode(response.data.data.id)
          TokenStorage.storeTokenExpiration(decoded.exp)
          TokenStorage.storeToken(response.data.data.id)
          TokenStorage.storeRefreshToken(response.data.data.attributes.refresh_token)
          if (TokenStorage.getToken() == null) {
            this.errors.push('No token found in login data')
            return
          }

          TokenStorage.setupTokenRefresh()
          this.$emit('loggedIn')
          if (this.$route.params.nextUrl == null) {
            this.$router.push('apps')
            return
          }
          this.$router.push(this.$route.params.nextUrl)
        })
        .catch((err) => {
          this.loading = false
          if (err.response) {
            err.response.data.errors.forEach(el => this.errors.push(el.title))
          } else if (err.request) {
            this.errors.push(err.request)
          } else {
            this.errors.push(err.toString())
          }
        })
    }
  }
}
</script>
