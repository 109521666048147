<template>
  <div>
    <loading v-if="loading" />

    <errors v-bind:errors="errors" />

    <div v-if="app" >
      <app-header :name="app.id"></app-header>

      <div class="px-4 py-5 sm:px-6">
        <main class="max-w-lg mx-auto px-4 lg:pb-16 space-y-10">
          <div class="border-b border-gray-200 pb-10">
            <h2 class="text-lg leading-6 font-medium text-gray-900">App Settings</h2>

            <form>
              <div class="mt-1">
                <ul class="mt-2 divide-y divide-gray-200">
                  <li class="py-4 flex items-center justify-between">
                    <div class="flex flex-col">
                      <p class="text-sm font-medium text-gray-900" id="privacy-option-1-label">
                        Deploy Lock
                      </p>
                      <p class="text-sm text-gray-500" id="privacy-option-1-description">
                        Unlocking an app is meant for cases where a previous app deploy failed in an unclean manner and a new deploy is desired to fix any issues.
                      </p>
                    </div>
                    <!-- Enabled: "bg-teal-500", Not Enabled: "bg-gray-200" -->
                    <button @click.prevent="app.attributes.locked = !app.attributes.locked" :class="app.attributes.locked ? 'bg-gray-800' : 'bg-gray-200'" :aria-pressed="app.attributes.locked" type="button" class="bg-gray-200 ml-4 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" aria-labelledby="privacy-option-1-label" aria-describedby="privacy-option-1-description">
                      <span class="sr-only">Use setting</span>
                      <!-- Enabled: "translate-x-5", Not Enabled: "translate-x-0" -->
                      <span :class="app.attributes.locked ? 'translate-x-5' : 'translate-x-0'" aria-hidden="true" class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"></span>
                    </button>
                  </li>
                </ul>

                <div class="flex justify-end">
                  <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" @click.prevent="updateApp(app)" :disabled="app.injected.saving">
                    <icon-check-circle class="-ml-1 mr-2 h-5 w-5 text-white" :class="{'animate-spin': app.injected.saving}" />
                    Save settings
                  </button>
                </div>

              </div>
            </form>
          </div>

          <div class="border-b border-gray-200 pb-10">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Linked Services</h2>

            <form>
              <div class="mt-1">
                <ul class="mt-2 ">

                  <li class="pb-4 flex items-center justify-between">
                    <div class="flex flex-col flex-1">
                      <div class="mt-1">
                        <div class="divide-y divide-gray-200">
                          <div class="flex flex-col">
                            <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                              <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                                <div class="overflow-hidden">
                                  <table class="min-w-full divide-y divide-gray-200">
                                    <tbody class="bg-white divide-y divide-gray-200">
                                      <tr v-for="(s) in linkedServices" :key="s.id" :value="s.id">
                                        <td class="pr-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{{ s.id }}</td>
                                        <td class="pl-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                          <button type="button" class="inline-flex items-center px-2 py-1 border border-transparent shadow-xs text-xs leading-4 font-small rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500" @click.prevent="unlinkService(s.id)" :disabled="service.updating">
                                            <icon-trash class="-ml-1 mr-2 h-5 w-5 text-white" :class="{'animate-spin': service.updating}" :disabled="service.updating" />
                                            Delete
                                          </button>
                                        </td>
                                      </tr>

                                      <!-- More people... -->
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <p class="text-sm text-gray-500 mb-2" id="privacy-option-1-description">
                        Select services to link to your app. Apps are rebuilt in the background with the new link information.
                      </p>

                      <div class="mt-1">
                        <select id="service" name="service" v-model="service.selected" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md">
                          <option v-for="(s) in unlinkedServices" :key="s.id" :value="s.id">{{ s.id }}</option>
                        </select>
                      </div>
                    </div>
                  </li>
                </ul>

                <div class="flex justify-end">
                  <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" @click.prevent="linkService(service.selected)" :disabled="service.updating">
                    <icon-link class="-ml-1 mr-2 h-5 w-5 text-white" :class="{'animate-spin': service.updating}" />
                    Link service
                  </button>
                </div>

              </div>
            </form>
          </div>

          <div class="border-b border-gray-200 pb-10">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Builder Settings</h2>

            <form>
              <div class="mt-1">
                <ul class="mt-2">
                  <li class="py-4 flex items-center justify-between">
                    <div class="flex flex-col flex-1">
                      <label for="country" class="text-sm font-medium text-gray-700 sm:mt-px">
                        App Builder
                      </label>
                      <p class="text-sm text-gray-500 mb-2" id="privacy-option-1-description">
                        The app builder controls how the build artifact gets built. The builder is automatically selected on each build if not specified.
                      </p>
                      <p class="text-sm text-gray-500 mb-2" id="privacy-option-1-description">
                        Changes will apply on next build.
                      </p>

                      <div class="mt-1">
                        <select id="builder" name="builder" v-model="builderSetting.attributes.computed_selected" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md" :disabled="app.injected.deleting">
                          <option v-for="(builder) in builders"  :key="builder.id" :value="builder.id">{{ builder.id }}</option>
                        </select>
                      </div>
                    </div>
                  </li>

                  <li class="py-4 flex items-center justify-between">
                    <div class="flex flex-col flex-1">
                      <label for="country" class="text-sm font-medium text-gray-700 sm:mt-px">
                        Build Directory
                      </label>
                      <p class="text-sm text-gray-500 mb-2" id="privacy-option-1-description">
                        The build directory the directory Dokku treats as the root of your application for building and configuration extraction. An empty build directory value means dokku will treat your repository root as the build directory.
                      </p>
                      <p class="text-sm text-gray-500 mb-2" id="privacy-option-1-description">
                        Changes will apply on next build.
                      </p>

                      <div class="mt-1">
                        <input id="build_dir" name="build_dir"  type="text" class="rounded-md w-full" v-model="builderSetting.attributes.computed_build_dir" :disabled="builderSetting.injected.saving">
                      </div>
                    </div>
                  </li>
                </ul>

                <div class="flex justify-end">
                  <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" @click.prevent="updateBuilderSetting(builderSetting)" :disabled="builderSetting.injected.saving">
                    <icon-check-circle class="-ml-1 mr-2 h-5 w-5 text-white" :class="{'animate-spin': builderSetting.injected.saving}" />
                    Save settings
                  </button>
                </div>

              </div>
            </form>
          </div>

          <div class="border-b border-gray-200 pb-10">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Scheduler Settings</h2>

            <form>
              <div class="mt-1">
                <ul class="mt-2 divide-y divide-gray-200">
                  <li class="py-4 flex items-center justify-between">
                    <div class="flex flex-col flex-1">
                      <label for="country" class="text-sm font-medium text-gray-700 sm:mt-px">
                        App Scheduler
                      </label>
                      <p class="text-sm text-gray-500 mb-2" id="privacy-option-1-description">
                        The app scheduler controls how the docker image build artifact gets run.
                      </p>
                      <p class="text-sm text-gray-500 mb-2" id="privacy-option-1-description">
                        Changes will apply on next deploy.
                      </p>

                      <div class="mt-1">
                        <select id="scheduler" name="scheduler" v-model="schedulerSetting.attributes.computed_selected" class="max-w-lg block focus:ring-indigo-500 focus:border-indigo-500 w-full shadow-sm sm:text-sm border-gray-300 rounded-md" :disabled="app.injected.deleting">
                          <option v-for="(scheduler) in schedulers"  :key="scheduler.id" :value="scheduler.id">{{ scheduler.id }}</option>
                        </select>
                      </div>
                    </div>
                  </li>
                </ul>

                <div class="flex justify-end">
                  <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" @click.prevent="updateSchedulerSetting(schedulerSetting)" :disabled="schedulerSetting.injected.saving">
                    <icon-check-circle class="-ml-1 mr-2 h-5 w-5 text-white" :class="{'animate-spin': schedulerSetting.injected.saving}" />
                    Save settings
                  </button>
                </div>

              </div>
            </form>
          </div>

          <div class="space-y-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Delete App</h2>

            <div class="bg-red-50 border-l-4 border-red-400 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <icon-exclamation class="h-5 w-5 text-red-400" />
                </div>
                <div class="ml-3">
                  <p class="text-sm text-red-700">
                    Deleting an app is a destructive action and cannot be reverted. Please type the app name <b>{{ app.id }}</b> to confirm deletion.
                  </p>
                </div>
              </div>
            </div>

            <form>
              <div class="mt-1">
                <input id="project_name" name="project_name"  type="text" class="rounded-md w-full" v-model="confirmDelete" :disabled="app.injected.deleting">
              </div>

              <transition name="fade">
                <div v-if="confirmDelete == app.id" class="py-4 flex justify-end">
                  <div v-if="app.injected.deleting" class="bg-yellow-50 p-2 rounded-md">
                    <div class="flex">
                      <div class="ml-3">
                        <p class="text-sm text-yellow-700">
                          Deleting an app takes a few seconds, hang tight!
                        </p>
                      </div>
                    </div>
                  </div>

                  <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" @click.prevent="deleteApp(app)" :disabled="app.injected.deleting">
                    <icon-check-circle class="-ml-1 mr-2 h-5 w-5 text-white" :class="{'animate-spin': app.injected.deleting}" />
                    Delete
                  </button>
                </div>
              </transition>

            </form>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/libraries/api.js'
import TokenStorage from '@/libraries/token-storage.js'
import { CheckCircleIcon, ExclamationIcon, LinkIcon, TrashIcon } from '@vue-hero-icons/solid'

export default {
  data () {
    return {
      app: null,
      appName: null,
      builders: null,
      builderSetting: null,
      confirmDelete: '',
      errors: [],
      loading: false,
      schedulers: null,
      schedulerSetting: null,
      service: null,
      services: null,
      service_updating: false
    }
  },

  created () {
    this.appName = this.$route.params.name
    this.fetchData()
  },

  beforeRouteEnter (to, from, next) {
    return TokenStorage.isAuthenticated() ? next() : next('/')
  },

  components: {
    'icon-check-circle': CheckCircleIcon,
    'icon-exclamation': ExclamationIcon,
    'icon-link': LinkIcon,
    'icon-trash': TrashIcon
  },

  computed: {
    linkedServices: function () {
      const us = []
      for (let i = 0; i < this.services.length; i++) {
        const hasLinks = Object.prototype.hasOwnProperty.call(this.services[i].attributes, 'links')
        if (!hasLinks) {
          continue
        }

        if (!this.services[i].attributes.links.includes(this.appName)) {
          continue
        }

        us.push(this.services[i])
      }

      return us
    },

    unlinkedServices: function () {
      const us = []
      for (let i = 0; i < this.services.length; i++) {
        const hasLinks = Object.prototype.hasOwnProperty.call(this.services[i].attributes, 'links')
        if (!hasLinks) {
          us.push(this.services[i])
          continue
        }

        if (this.services[i].attributes.links.includes(this.appName)) {
          continue
        }

        us.push(this.services[i])
      }

      return us
    }
  },

  methods: {
    fetchData () {
      this.errors = []
      this.app = null
      this.loading = true
      Api.componentAppSettings(this.appName, (appResponse, builderResponse, builderSettingResponse, schedulerSettingResponse, schedulerResponse, servicesResponse) => {
        appResponse.data.data.injected = {
          deleting: false,
          saving: false
        }
        builderSettingResponse.data.data.injected = {
          deleting: false,
          saving: false
        }
        schedulerSettingResponse.data.data.injected = {
          deleting: false,
          saving: false
        }

        // without this injection of the `links` attribute
        // the property is not reactive
        // see: https://v2.vuejs.org/v2/guide/reactivity.html?redirect=true#Change-Detection-Caveats
        for (let i = 0; i < servicesResponse.data.data.length; i++) {
          const hasLinks = Object.prototype.hasOwnProperty.call(servicesResponse.data.data[i].attributes, 'links')
          if (!hasLinks) {
            servicesResponse.data.data[i].attributes.links = []
          }
        }

        this.app = appResponse.data.data
        this.builders = builderResponse.data.data
        this.builderSetting = builderSettingResponse.data.data
        this.schedulerSetting = schedulerSettingResponse.data.data
        this.schedulers = schedulerResponse.data.data
        this.services = servicesResponse.data.data
        this.service = {
          selected: '',
          updating: false
        }
        this.loading = false
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    deleteApp (app) {
      app.injected.deleting = true

      Api.deleteApp(this.appName, (response) => {
        app.injected.deleting = false
        this.$router.push({
          name: 'appsList'
        })
      }, (err) => {
        app.injected.deleting = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    linkService (serviceID) {
      const that = this
      const services = that.services
      that.service.updating = true

      const data = {
        data: [
          {
            type: 'services',
            id: serviceID
          }
        ]
      }

      Api.linkService(that.appName, data, (response) => {
        for (let i = 0; i < services.length; i++) {
          if (services[i].id !== serviceID) {
            continue
          }

          const hasLinks = Object.prototype.hasOwnProperty.call(services[i].attributes, 'links')
          if (!hasLinks) {
            services[i].attributes.links = []
          }

          if (services[i].attributes.links.includes(that.appName)) {
            continue
          }

          services[i].attributes.links.push(that.appName)
        }

        that.service.updating = false
        that.services = services
      }, (err) => {
        that.service.updating = false
        if (err.response) {
          err.response.data.errors.forEach(el => that.errors.push(el.title))
        } else if (err.request) {
          that.errors.push(err.request)
        } else {
          that.errors.push(err.toString())
        }
      })
    },

    unlinkService (serviceID) {
      const that = this
      const services = that.services
      that.service.updating = true

      const data = {
        data: [
          {
            type: 'services',
            id: serviceID
          }
        ]
      }

      Api.unlinkService(that.appName, data, (response) => {
        for (let i = 0; i < services.length; i++) {
          if (services[i].id !== serviceID) {
            continue
          }

          const hasLinks = Object.prototype.hasOwnProperty.call(services[i].attributes, 'links')
          if (!hasLinks) {
            services[i].attributes.links = []
          }

          if (!services[i].attributes.links.includes(that.appName)) {
            continue
          }

          services[i].attributes.links = services[i].attributes.links.filter(el => el !== that.appName)
        }

        that.service.updating = false
        that.services = services
      }, (err) => {
        that.service.updating = false
        if (err.response) {
          err.response.data.errors.forEach(el => that.errors.push(el.title))
        } else if (err.request) {
          that.errors.push(err.request)
        } else {
          that.errors.push(err.toString())
        }
      })
    },

    updateApp (app) {
      app.injected.saving = true

      const data = {
        data: {
          type: 'apps',
          id: this.appName,
          attributes: {
            locked: app.attributes.locked
          }
        }
      }

      Api.updateApp(this.appName, data, (response) => {
        app = response.data.data
        app.injected.saving = false
      }, (err) => {
        app.injected.saving = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    updateBuilderSetting (builderSetting) {
      builderSetting.injected.saving = true

      const data = {
        data: {
          type: builderSetting.type,
          id: builderSetting.id,
          attributes: {
            build_dir: builderSetting.attributes.computed_build_dir,
            selected: builderSetting.attributes.computed_selected
          }
        }
      }

      Api.updateBuilderSetting(this.appName, data, (response) => {
        builderSetting.attributes.build_dir = response.data.data.attributes.computed_build_dir
        builderSetting.attributes.selected = response.data.data.attributes.computed_selected
        builderSetting.injected.saving = false
      }, (err) => {
        builderSetting.injected.saving = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    updateSchedulerSetting (schedulerSetting) {
      schedulerSetting.injected.saving = true

      const data = {
        data: {
          type: schedulerSetting.type,
          id: schedulerSetting.id,
          attributes: {
            selected: schedulerSetting.attributes.computed_selected
          }
        }
      }

      Api.updateSchedulerSetting(this.appName, data, (response) => {
        schedulerSetting.attributes.selected = response.data.data.attributes.computed_selected
        schedulerSetting.injected.saving = false
      }, (err) => {
        schedulerSetting.injected.saving = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    }
  },

  watch: {
    $route: 'fetchData'
  }
}
</script>
