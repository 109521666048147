import axios from 'axios'
import ApiUrlService from '@/libraries/api-url-service.js'
import TokenStorage from '@/libraries/token-storage.js'

export default {

  createApp (data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .post(ApiUrlService.appsCreate(), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  createDomain (data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .post(ApiUrlService.domainCreate(), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  createEnv (data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .post(ApiUrlService.envCreate(), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  createService (data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .post(ApiUrlService.serviceCreate(), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  deleteApp (name, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .delete(ApiUrlService.appsDelete(name), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  deleteDomain (name, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .delete(ApiUrlService.domainDelete(name), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  deleteEnv (name, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .delete(ApiUrlService.envDelete(name), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  deleteService (serviceType, name, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .delete(ApiUrlService.serviceDelete(serviceType, name), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  getApp (name, fields, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .get(ApiUrlService.appsView(name) + (fields.length > 0 ? '?fields=' + fields : ''), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  getAppFull (name, onSuccess, onError) {
    const fields = 'sha,locked,deployed,app_status,created_at,updated_at,last_visited_at'
    this.getApp(name, fields, onSuccess, onError)
  },

  getApps (onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .get(ApiUrlService.appsList(), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  getDatastores (onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .get(ApiUrlService.datastoresList(), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  getDomains (name, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .get(ApiUrlService.appDomains(name), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  getEnv (name, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .get(ApiUrlService.appEnv(name), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  getSchedulers (onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .get(ApiUrlService.schedulersList(), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  getService (serviceType, name, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .get(ApiUrlService.serviceView(serviceType, name), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  linkService (name, data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .post(ApiUrlService.appServiceRelationships(name), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  componentAppResources (name, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .get(ApiUrlService.appFormation(name), { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  componentAppSettings (name, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    const requests = [
      axios.get(ApiUrlService.appsView(name) + '?fields=locked', { headers: headers }),
      axios.get(ApiUrlService.buildersList(), { headers: headers }),
      axios.get(ApiUrlService.appBuilderSetting(name), { headers: headers }),
      axios.get(ApiUrlService.appSchedulerSetting(name), { headers: headers }),
      axios.get(ApiUrlService.schedulersList(), { headers: headers }),
      axios.get(ApiUrlService.serviceList() + '?fields=links', { headers: headers })
    ]

    axios
      .all(requests)
      .then(axios.spread(function (response1, response2, response3, response4, response5, response6) {
        onSuccess(response1, response2, response3, response4, response5, response6)
      }))
      .catch(error => {
        onError(error)
      })
  },

  componentDatastoresList (onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    const requests = [
      axios.get(ApiUrlService.datastoresList(), { headers: headers }),
      axios.get(ApiUrlService.serviceList(), { headers: headers })
    ]

    axios
      .all(requests)
      .then(axios.spread(function (response2, response3) {
        onSuccess(response2, response3)
      }))
      .catch(error => {
        onError(error)
      })
  },

  componentScaleUpdate (data, data2, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    const requests = [
      axios.post(ApiUrlService.formationCreate(), data, { headers: headers }),
      axios.post(ApiUrlService.resourceCreate(), data2, { headers: headers })
    ]

    axios
      .all(requests)
      .then(axios.spread(function (response2, response3) {
        onSuccess(response2, response3)
      }))
      .catch(error => {
        onError(error)
      })
  },

  updateApp (name, data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .patch(ApiUrlService.appsUpdate(name), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  updateBuilderSetting (name, data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .patch(ApiUrlService.builderSettingUpdate(name), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  updateFormation (data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .post(ApiUrlService.formationCreate(), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  updateResource (data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .post(ApiUrlService.resourceCreate(), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  unlinkService (name, data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .delete(ApiUrlService.appServiceRelationships(name), { data: data, headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  },

  updateSchedulerSetting (name, data, onSuccess, onError) {
    const headers = {
      Authorization: `Bearer ${TokenStorage.getToken()}`
    }

    axios
      .patch(ApiUrlService.schedulerSettingUpdate(name), data, { headers: headers })
      .then(response => {
        onSuccess(response)
      })
      .catch(error => {
        onError(error)
      })
  }
}
