<template>
  <div>
    <loading v-if="loading" />

    <errors v-bind:errors="errors" />

    <div v-if="formations" >
      <app-header :name="appName"></app-header>
        <div class="px-4 py-5 sm:px-6">
          <div class="pb-5">
            <p class="text-sm leading-5 text-gray-500">
              Memory and CPU resources can be soft limited - others are hard limits - and may be zero'd out to allow unlimited resource utilization.
            </p>
          </div>

          <div class="bg-white shadow overflow-hidden sm:rounded-md">
            <ul>

              <li v-for="formation in formations" :key="formation.id" class="block hover:bg-gray-50 focus:outline-none focus:bg-gray-50 transition duration-150 ease-in-out">
                <app-scale v-bind:appName="appName" v-bind:formation="formation"></app-scale>
              </li>
            </ul>
          </div>

        </div>

    </div>
  </div>
</template>

<script>
import Api from '@/libraries/api.js'
import TokenStorage from '@/libraries/token-storage.js'
import AppScale from '@/components/elements/AppScale.vue'

export default {
  data () {
    return {
      appName: null,
      formations: null,
      errors: null,
      loading: false,

      // TODO: do not hardcode this
      maxCpu: 4
    }
  },

  created () {
    this.appName = this.$route.params.name
    this.fetchData()
  },

  beforeRouteEnter (to, from, next) {
    return TokenStorage.isAuthenticated() ? next() : next('/')
  },

  components: {
    'app-scale': AppScale
  },

  methods: {
    fetchData () {
      this.errors = []
      this.formations = null
      this.loading = true
      Api.componentAppResources(this.appName, (formationResponse) => {
        for (const element of formationResponse.data.data) {
          element.injected = {
            saving: false
          }
        }
        this.formations = formationResponse.data.data
        this.loading = false
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    }
  },

  watch: {
    $route: 'fetchData'
  }
}
</script>

<style>
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .custom-number-input input:focus {
    outline: none !important;
  }

  .custom-number-input button:focus {
    outline: none !important;
  }
</style>
