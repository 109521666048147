import Vue from 'vue'
import Router from 'vue-router'
import AppDomains from '@/components/pages/AppDomains'
import Apps from '@/components/pages/Apps'
import AppsList from '@/components/pages/AppsList'
import AppEnv from '@/components/pages/AppEnv'
import AppLogs from '@/components/pages/AppLogs'
import AppResources from '@/components/pages/AppResources'
import AppSettings from '@/components/pages/AppSettings'
import AppView from '@/components/pages/AppView'
import DatastoresList from '@/components/pages/DatastoresList'
import NotFound from '@/components/pages/NotFound'
import Login from '@/components/pages/Login'
import ServiceLogs from '@/components/pages/ServiceLogs'
import ServiceSettings from '@/components/pages/ServiceSettings'
import ServiceView from '@/components/pages/ServiceView'
import TokenStorage from '@/libraries/token-storage.js'
import LayoutEmpty from '@/layouts/LayoutEmpty.vue'
import LayoutDefault from '@/layouts/LayoutDefault.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'login',
      component: Login,
      meta: {
        guest: true,
        layout: LayoutEmpty
      }
    },
    {
      path: '/apps',
      name: 'appsList',
      component: AppsList,
      meta: {
        layout: LayoutDefault,
        requiresAuth: true
      }
    },
    {
      path: '/apps/:name',
      component: Apps,
      meta: {
        layout: LayoutDefault,
        requiresAuth: true
      },
      children: [
        {
          path: '',
          component: AppView,
          name: 'appView',
          meta: {
            layout: LayoutDefault,
            requiresAuth: true
          }
        },
        {
          path: 'logs',
          component: AppLogs,
          name: 'appLogs',
          meta: {
            layout: LayoutDefault,
            requiresAuth: true
          }
        },
        {
          path: 'env',
          component: AppEnv,
          name: 'appEnv',
          meta: {
            layout: LayoutDefault,
            requiresAuth: true
          }
        },
        {
          path: 'resources',
          component: AppResources,
          name: 'appResources',
          meta: {
            layout: LayoutDefault,
            requiresAuth: true
          }
        },
        {
          path: 'domains',
          component: AppDomains,
          name: 'appDomains',
          meta: {
            layout: LayoutDefault,
            requiresAuth: true
          }
        },
        {
          path: 'settings',
          component: AppSettings,
          name: 'appSettings',
          meta: {
            layout: LayoutDefault,
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: '/services',
      name: 'datastoresList',
      component: DatastoresList,
      meta: {
        layout: LayoutDefault,
        requiresAuth: true
      }
    },
    {
      path: '/services/:name',
      component: ServiceView,
      name: 'serviceView',
      meta: {
        layout: LayoutDefault,
        requiresAuth: true
      }
    },
    {
      path: '/services/:name/logs',
      component: ServiceLogs,
      name: 'serviceLogs',
      meta: {
        layout: LayoutDefault,
        requiresAuth: true
      }
    },
    {
      path: '/services/:name/settings',
      component: ServiceSettings,
      name: 'serviceSettings',
      meta: {
        layout: LayoutDefault,
        requiresAuth: true
      }
    },
    {
      path: '/404',
      name: '404',
      component: NotFound,
      meta: {
        layout: LayoutEmpty
      }
    },
    {
      path: '*',
      redirect: '/404'
    }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (TokenStorage.getToken() == null || TokenStorage.isTokenExpired()) {
      next({
        path: '/',
        params: { nextUrl: to.fullPath }
      })
      return
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (TokenStorage.getToken() !== null && !TokenStorage.isTokenExpired()) {
      next({ name: 'appsList' })
      return
    }
  }
  next()
})

export default router
