// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueLogger from 'vuejs-logger'
import VueMoment from 'vue-moment'
import VueUuid from 'vue-uuid'
import App from '@/App'
import Spinner from 'vue-simple-spinner'
import router from '@/router'
import Axios from 'axios'
import interceptor from '@/libraries/axios-interceptor.js'

import AppHeader from '@/components/elements/AppHeader.vue'
import ServiceHeader from '@/components/elements/ServiceHeader.vue'
import Breadcrumb from '@/components/elements/Breadcrumb.vue'
import Errors from '@/components/elements/Errors.vue'
import Loading from '@/components/elements/Loading.vue'
import Modal from '@/components/elements/Modal.vue'
import './assets/styles/index.css'

interceptor()

const isProduction = process.env.NODE_ENV === 'production'

Vue.prototype.$http = Axios

Vue.config.productionTip = false

Vue.use(VueUuid)
Vue.use(VueMoment)

Vue.use(VueLogger, {
  isEnabled: true,
  logLevel: isProduction ? 'error' : 'debug',
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: '|',
  showConsoleColors: true
})

Vue.component('app-header', AppHeader)
Vue.component('service-header', ServiceHeader)
Vue.component('breadcrumb', Breadcrumb)
Vue.component('errors', Errors)
Vue.component('loading', Loading)
Vue.component('modal', Modal)
Vue.component('spinner', Spinner)

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   components: { App },
//   template: '<App/>'
// })
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
