<template>
  <div>
    <loading v-if="loading" />

    <errors v-bind:errors="errors" />

    <div v-if="app" >
      <app-header :name="app.id"></app-header>

      <div class="px-4 py-5 sm:px-6">
        <dl class="grid grid-cols-1 col-gap-4 row-gap-8 sm:grid-cols-2">
          <div class="sm:col-span-1">
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Status
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5" :class="app.attributes.app_status | app_status_class" :data-app-status="app.attributes.app_status">
              <span v-html="this.$options.filters.app_status_icon(app.attributes.app_status)"></span> {{ app.attributes.app_status | titleize }}
            </dd>
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Deploys Locked?
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-app-locked="app.attributes.locked">
              {{ app.attributes.locked }}
            </dd>
            <dt v-if="app.attributes.sha" class="text-sm leading-5 font-medium text-gray-500">
              Git Sha
            </dt>
            <dd v-if="app.attributes.sha" class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-git-sha="app.attributes.sha">
              {{ app.attributes.sha }}
            </dd>
          </div>

          <div class="sm:col-span-1">
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Created At
            </dt>
            <dd class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-created-at="app.attributes.created_at">
              {{ app.attributes.created_at | moment("from") }}
            </dd>
            <dt v-if="app.attributes.sha" class="text-sm leading-5 font-medium text-gray-500">
              Updated At
            </dt>
            <dd v-if="app.attributes.sha" class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-updated-at="app.attributes.updated_at">
              {{ app.attributes.updated_at  | moment("from") }}
            </dd>
            <dt v-if="app.attributes.sha" class="text-sm leading-5 font-medium text-gray-500">
              Last Visited At
            </dt>
            <dd v-if="app.attributes.sha" class="mb-2 mt-1 text-sm leading-5 text-gray-900" :data-last-visited-at="app.attributes.last_visited_t">
              {{ app.attributes.last_visited_at | moment("from")  }}
            </dd>
          </div>

          <div class="sm:col-span-1">
            <dt class="text-sm leading-5 font-medium text-gray-500">
              Push URL
            </dt>
            <div class="text-xs leading-3 font-normal text-gray-400">
              A push URL is used for updating your application with git on your server.
            </div>
          </div>

          <div class="sm:col-span-1">
            <dd class="mt-1 text-sm leading-5 text-gray-900">

              <div>
                <label for="push_url" class="sr-only">
                  Push URL
                </label>
                <div class="mt-1 sm:mt-0 sm:col-span-2">
                  <div class="max-w-lg flex rounded-md shadow-sm">
                    <input type="text" disabled class="inline-flex items-center px-3 rounded-l-md bg-gray-50 text-gray-500 w-14 text-center" :value="push_proto" />
                    <input id="push_url" type="text" class="flex-1 w-full rounded-none rounded-r-md" :placeholder="push_url" :value="push_url" />
                  </div>
                </div>
              </div>

            </dd>
          </div>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/libraries/api.js'
import TokenStorage from '@/libraries/token-storage.js'

export default {
  data () {
    return {
      app: null,
      appName: null,
      errors: [],
      loading: false,
      push_proto: location.protocol.slice(0, -1),
      push_url: `${location.protocol}//${location.host}/@git/${this.$route.params.name}`
    }
  },

  created () {
    this.appName = this.$route.params.name
    this.fetchData()
  },

  beforeRouteEnter (to, from, next) {
    return TokenStorage.isAuthenticated() ? next() : next('/')
  },

  filters: {
    titleize (value) {
      return value.replace(/(?:^|\s|-)\S/g, x => x.toUpperCase())
    },

    app_status_class (s) {
      if (s === 'running') {
        return 'text-green-700'
      }

      if (s === 'mixed') {
        return 'text-yellow-600'
      }

      if (s === 'stopped') {
        return 'text-red-700'
      }

      return 'text-gray-600'
    },

    app_status_icon (s) {
      if (s === 'running') {
        return '<svg style="display:inline" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="inline-block mr-2 h-4 w-4"><path d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
      }

      if (s === 'mixed') {
        return '<svg style="display:inline" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="inline-block mr-2 h-4 w-4"><path d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
      }

      if (s === 'stopped') {
        return '<svg style="display:inline" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="inline-block mr-2 h-4 w-4"><path d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
      }

      return '<svg style="display:inline" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" class="w-8 h-8"><path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>'
    }
  },

  methods: {
    fetchData () {
      this.errors = []
      this.app = null
      this.loading = true
      Api.getAppFull(this.appName, (response) => {
        this.app = response.data.data
        this.loading = false
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    }
  },

  watch: {
    $route: 'fetchData'
  }
}
</script>
