<template>
  <div>
    <loading v-if="loading" />

    <errors v-bind:errors="errors" />

    <div v-if="domains" >
      <app-header :name="appName"></app-header>

      <div class="px-4 py-5 sm:px-6">
        <div class="pb-5">
          <p class="text-sm leading-5 text-gray-500">
            This is a list of all domains associated with the app. Changing global domains will not modify this list.
          </p>
        </div>

        <form>
          <div v-for="(domain, index) in domains" :key="domain.id" class="mb-2 flex">
            <div class="flex-1">
              <label :for="'domain[' + domain.id + ']'" class="sr-only">
                Domain Name
              </label>
              <div>
                <input type="text" :id="'domain[' + domain.id + ']'" class="rounded-md w-full" v-model="domain.attributes.domain"  />
              </div>
            </div>

            <div class="flex-none w-14">
              <div class="ml-3 inline-flex">
                <button v-if="domain.injected.temporary"  class="btn--add-domain" @click.prevent="saveDomain(index, domains)" :disabled="domain.injected.saving">
                  <icon-check-circle class="-ml-1 mr-2 h-5 w-5 text-green-400" :class="{'animate-spin': domain.injected.saving}" />
                </button>
                <button class="btn--remove-domain" @click.prevent="removeDomain(index, domains)" :disabled="domain.injected.removing">
                  <icon-x-circle class="h-5 w-5 text-red-400" :class="{'animate-spin': domain.injected.removing}" />
                </button>
              </div>
            </div>
          </div>

          <button class="btn--new-domain" @click.prevent="addDomain()">
            <icon-plus-circle class="-ml-1 mr-2 h-5 w-5 text-blue-400"/>
            Add New Domain Name
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<style>
.btn--add-domain {
  @apply relative inline-flex items-center px-4 py-2 border text-xs leading-5 font-medium rounded-md bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-300 text-green-700 hover:text-green-500 active:bg-green-50 active:text-green-800 border-green-300 mr-4;
}
.btn--remove-domain {
  @apply relative inline-flex items-center px-4 py-2 border text-xs leading-5 font-medium rounded-md bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-300 text-red-700 hover:text-red-500 active:bg-red-50 active:text-red-800 border-red-300;
}
.btn--new-domain {
  @apply relative inline-flex items-center px-4 py-2 border text-xs leading-5 font-medium rounded-md bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-300 text-blue-700 hover:text-blue-500 active:bg-blue-50 active:text-blue-800 border-blue-300;
}
</style>

<script>
import Api from '@/libraries/api.js'
import TokenStorage from '@/libraries/token-storage.js'
import { CheckCircleIcon, PlusCircleIcon, XCircleIcon } from '@vue-hero-icons/outline'

export default {
  data () {
    return {
      appName: null,
      domains: null,
      errors: null,
      loading: false
    }
  },

  created () {
    this.appName = this.$route.params.name
    this.fetchData()
  },

  beforeRouteEnter (to, from, next) {
    return TokenStorage.isAuthenticated() ? next() : next('/')
  },

  components: {
    'icon-check-circle': CheckCircleIcon,
    'icon-plus-circle': PlusCircleIcon,
    'icon-x-circle': XCircleIcon
  },

  methods: {
    fetchData () {
      this.errors = []
      this.domains = null
      this.loading = true
      const fetchedName = this.$route.params.name
      Api.getDomains(fetchedName, (domainsResponse) => {
        for (const element of domainsResponse.data.data) {
          element.injected = {
            saving: false,
            removing: false,
            temporary: false
          }
        }
        if (domainsResponse.data.data.length === 0) {
          this.domains = []
        } else {
          this.domains = domainsResponse.data.data
        }
        this.loading = false
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    addDomain () {
      this.domains.push({
        type: 'domains',
        id: this.$uuid.v4(),
        injected: {
          saving: false,
          removing: false,
          temporary: true
        },
        attributes: {
          app_id: this.appName,
          domain: ''
        }
      })
    },

    removeDomain (index, domains) {
      if (domains[index].injected.temporary) {
        domains.splice(index, 1)
        return
      }

      domains[index].injected.removing = true
      Api.deleteDomain(domains[index].id, () => {
        domains[index].injected.removing = false
        domains.splice(index, 1)
      }, (err) => {
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    saveDomain (index, domains) {
      domains[index].injected.saving = true

      const id = this.appName + ':' + domains[index].attributes.domain
      const data = {
        data: {
          type: 'domains',
          id: id,
          attributes: {
            app_id: this.appName,
            domain: domains[index].attributes.domain
          }
        }
      }

      Api.createDomain(data, () => {
        domains[index].id = id
        domains[index].injected.temporary = false
        domains[index].injected.saving = false
      }, (err) => {
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    }
  },

  watch: {
    $route: 'fetchData'
  }
}
</script>
