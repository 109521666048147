<template>
  <component :is="this.$route.meta.layout || 'div'">
    <router-view />
  </component>
</template>

<script>
import TokenStorage from '@/libraries/token-storage.js'

export default {
  name: 'App',
  data () {
    return {
      layout: 'div'
    }
  },

  mounted () {
    TokenStorage.setupTokenRefresh()
  }
}
</script>
