<template>
  <div>
    <nav class="bg-gray-800">
      <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
          <div class="flex items-center">
            <div class="flex-shrink-0">
              <img class="h-8 w-8" src="https://cdn.jsdelivr.net/gh/dokku/dokku@v0.20.4/docs/assets/dokku.png" alt="logo" />
            </div>
            <div class="hidden md:block">
              <div class="ml-10 flex items-baseline">
                <router-link v-for="link in navigation" :key="link.name" :to="link.href" class="px-3 py-2 rounded-md text-sm font-medium text-white focus:outline-none focus:text-white focus:bg-gray-700 hover:bg-gray-700" :class="{ 'bg-gray-900': $route.path.startsWith(link.href) }">
                  {{ link.name }}
                </router-link>
              </div>
            </div>
          </div>
          <div class="hidden sm:ml-6 sm:flex sm:items-center">
            <button @click="logout()" class="px-3 py-2 rounded-md text-sm font-medium text-white focus:outline-none focus:text-white focus:bg-gray-700 hover:bg-gray-700">Logout</button>
          </div>
        </div>
      </div>

      <div class="hidden md:hidden">
        <div class="px-2 pt-2 pb-3 sm:px-3">
          <router-link to="/apps" class="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700">Apps</router-link>
        </div>
        <div class="px-2 pt-2 pb-3 sm:px-3">
          <router-link to="/services" class="block px-3 py-2 rounded-md text-base font-medium text-white bg-gray-900 focus:outline-none focus:text-white focus:bg-gray-700">Services</router-link>
        </div>
      </div>
    </nav>

    <header class="bg-white shadow-sm">
      <div class="max-w-7xl mx-auto py-4 px-4 sm:px-6 lg:px-8">
        <h1 class="text-lg leading-6 font-semibold text-gray-900">
          <breadcrumb></breadcrumb>
        </h1>
      </div>
    </header>

    <main>
      <div class="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div class="bg-white shadow overflow-hidden sm:rounded-lg">
          <slot/>
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import TokenStorage from '@/libraries/token-storage.js'

const navigation = [
  { name: 'Apps', href: '/apps' },
  { name: 'Services', href: '/services' }
]

export default {
  data () {
    return {
      navigation: navigation
    }
  },

  methods: {
    logout () {
      TokenStorage.clear()
      if (this.$router.currentRoute.name !== 'login') {
        this.$router.push({
          name: 'login'
        })
      }
    }
  }
}
</script>
