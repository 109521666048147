<template>
  <div>
    <loading v-if="loading" />

    <errors v-bind:errors="errors" />

    <div v-if="service" >
      <service-header :name="serviceName" :service_type="serviceType"></service-header>

      <div class="px-4 py-5 sm:px-6">
        <main class="max-w-lg mx-auto px-4 lg:pb-16 space-y-10">
          <div class="space-y-6">
            <h2 class="text-lg leading-6 font-medium text-gray-900">Delete Service</h2>

            <div class="bg-red-50 border-l-4 border-red-400 p-4">
              <div class="flex">
                <div class="flex-shrink-0">
                  <icon-exclamation class="h-5 w-5 text-red-400" />
                </div>
                <div class="ml-3">
                  <p class="text-sm text-red-700">
                    Deleting a service is a destructive action and cannot be reverted. Please type the service name <b>{{ serviceName }}</b> to confirm deletion.
                  </p>
                </div>
              </div>
            </div>

            <form>
              <div class="mt-1">
                <input id="project_name" name="project_name"  type="text" class="rounded-md w-full" v-model="confirmDelete" :disabled="service.injected.deleting">
              </div>

              <transition name="fade">
                <div v-if="confirmDelete == serviceName" class="py-4 flex justify-end">
                  <div v-if="service.injected.deleting" class="bg-yellow-50 p-2 rounded-md">
                    <div class="flex">
                      <div class="ml-3">
                        <p class="text-sm text-yellow-700">
                          Deleting a service takes a few seconds, hang tight!
                        </p>
                      </div>
                    </div>
                  </div>

                  <button type="submit" class="ml-3 inline-flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-light-blue-500" @click.prevent="deleteService(service)" :disabled="service.injected.deleting">
                    <icon-check-circle class="-ml-1 mr-2 h-5 w-5 text-white" :class="{'animate-spin': service.injected.deleting}" />
                    Delete
                  </button>
                </div>
              </transition>

            </form>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import Api from '@/libraries/api.js'
import TokenStorage from '@/libraries/token-storage.js'
import { CheckCircleIcon, ExclamationIcon } from '@vue-hero-icons/solid'

export default {
  data () {
    return {
      service: null,
      serviceName: null,
      serviceType: null,
      errors: [],
      loading: false,
      confirmDelete: ''
    }
  },

  created () {
    this.serviceName = this.parseServiceName(this.$route.params.name)
    this.serviceType = this.parseServiceType(this.$route.params.name)
    this.fetchData()
  },

  beforeRouteEnter (to, from, next) {
    return TokenStorage.isAuthenticated() ? next() : next('/')
  },

  components: {
    'icon-check-circle': CheckCircleIcon,
    'icon-exclamation': ExclamationIcon
  },

  methods: {
    fetchData () {
      this.errors = []
      this.service = null
      this.loading = true
      Api.getService(this.serviceType, this.serviceName, (response) => {
        response.data.data.injected = {
          deleting: false,
          saving: false
        }

        this.service = response.data.data
        this.loading = false
      }, (err) => {
        this.loading = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    deleteService (service) {
      service.injected.deleting = true

      Api.deleteService(this.serviceType, this.serviceName, (response) => {
        service.injected.deleting = false
        this.$router.push({
          name: 'datastoresList'
        })
      }, (err) => {
        service.injected.deleting = false
        if (err.response) {
          err.response.data.errors.forEach(el => this.errors.push(el.title))
        } else if (err.request) {
          this.errors.push(err.request)
        } else {
          this.errors.push(err.toString())
        }
      })
    },

    parseServiceName (name) {
      return name.split(':')[1]
    },

    parseServiceType (name) {
      return name.split(':')[0]
    }
  },

  watch: {
    $route: 'fetchData'
  }
}
</script>
