import axios from 'axios'
import { Promise } from 'es6-promise'
import TokenStorage from '@/libraries/token-storage'
import ApiUrlService from '@/libraries/api-url-service.js'

export default () => {
  axios.interceptors.response.use((response) => {
    // Return a successful response back to the calling service
    return response
  }, (error) => {
    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    // Do nothing if it was a refreshToken request
    if (error.config.url === ApiUrlService.refreshToken()) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    // Try request again with new token
    return TokenStorage.getNewToken()
      .then((token) => {
        // New request with new token
        const config = error.config
        config.headers.Authorization = `Bearer ${token}`

        return new Promise((resolve, reject) => {
          axios.request(config).then(response => {
            resolve(response)
          }).catch((error) => {
            reject(error)
          })
        })
      })
      .catch((error) => {
        Promise.reject(error)
      })
  })
}
